<template>
	<MobileAppWizardStep
		v-model="application"
		:steps="steps"
		:start-with-last="startWithLast"
		:step-name="stepName"
		@previous="$emit('previous')"
		@next="$emit('next')"
		@unvalidated="$emit('unvalidated')"
		@validated="$emit('validated')"
	/>
</template>

<script>
export default {
	name: 'MobileAppStepBitrise',
	components: {
		MobileAppWizardStep: () => ({
			component: import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppWizardStep')
		})
	},
	props: {
		value: {
			required: true,
			type: Object
		},
		startWithLast: {
			required: false,
			type: Boolean,
			default: false
		},
		stepName: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			stepsValidated: false,
			steps: [
				{
					title: this.$t('mobileapp.creationSteps.bitrise.title'),
					href: () => 'https://app.bitrise.io/app/8fe26d0ef54801ba',
					valueToCopy: () => 'admin@welyb.fr'
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.2.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.3.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.4.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.5.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.6.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.7.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.8.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.9.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.10.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.11.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.12.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.13.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.13-1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.17.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.18.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.19.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.20.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.21.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.22.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.14.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.23.title'),
					valueToCopy: () => this.application.promotional_text
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.24.title'),
					valueToCopy: () => this.application.description
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.25.title'),
					valueToCopy: () => this.application.keywords.split('\n').join(', ')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.26.title'),
					valueToCopy: () => this.application.support_url
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.27.title'),
					valueToCopy: () => this.application.marketing_url
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.28.title'),
					valueToCopy: () => `${new Date().getFullYear()}, Welyb SAS`
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.29.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.30.title'),
					valueToCopy: () => 'Admin'
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.31.title'),
					valueToCopy: () => 'Welyb'
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.32.title'),
					valueToCopy: () => 'admin@welyb.fr'
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.33.title'),
					valueToCopy: () => '+33616287901'
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.34.title'),
					valueToCopy: () => 'login@welyb.fr'
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.35.title'),
					valueToCopy: () => 'password'
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.36.title'),
					valueToCopy: () =>
						[
							'1 - Who is the target audience?',
							'The App target audience is customers of the eponym accounting firm, which includes companies and their employees, accounting firm employees, partners and individuals (eg. for personal finance, taxes, etc. management).',
							'',
							'2 - How do users obtain an account?',
							'The accounting firm distributes logins and password, their customers can also invite their employees and partners. The accounting firm must insure of KYC as well as a contract agreement before issuing login and password to customers as enforced by laws.',
							'',
							'3 - Is this app meant for internal distribution in your own company or in the company of one target client?',
							'No this application needs to be downloaded publicly as some users are individuals.',
							'',
							'4 - Is the app meant for multiple target clients’ companies?',
							'Yes',
							'',
							'5 - In which countries will this app primarily be distributed?',
							'France',
							'',
							'6 - If this app is meant for internal distribution, will the app be accessible by both internal and external partners?',
							'This app is NOT meant for internal distribution, the app will be accessible to both internal and external users including individuals.',
							'',
							'7 - Will the app be exclusive to in-house employees?',
							'No, the app will be used by individuals, companies, employees, partners, etc ...'
						].join('\n')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.37.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.38.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.39.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.40.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.41.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.42.title'),
					valueToCopy: () => 'https://www.welyb.fr/cgv-cgu'
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.43.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.44.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.45.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.46.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.47.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.48.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.49.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.50.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.51.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.52.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.53.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.54.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.55.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.56.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.57.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.58.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.59.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.60.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.61.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.bitrise.steps.62.title')
				}
			]
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
